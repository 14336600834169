import React, { useEffect } from 'react';
import './AboutSection.css';
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube, FaSpotify, FaMapMarkerAlt } from 'react-icons/fa'; // Social media and location pin icons
import bwImage from '../assets/bw.jpg'; // Importing the image correctly

import AOS from 'aos';
import 'aos/dist/aos.css';

const AboutSection = () => {
  // Initialize AOS for scroll animations
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration
      once: false, // Trigger animations every time they are in view (not just once)
      offset: 100, // Offset to trigger the animation a bit before the section is fully in view
    });
  }, []); // Empty dependency array ensures it runs once when the component mounts

  return (
    <section className="about">
      <div className="about-content">
        <div className="about-text">
          <h2 className="about-title" data-aos="fade-up">
            I am Joy: A Journey of Gospel and Inspiration
          </h2>
          <div className="location" data-aos="fade-up" data-aos-delay="200">
            <FaMapMarkerAlt /> <span>Hamiltonganj, West Bengal - India</span>
          </div>
          <p className="about-description" data-aos="fade-up" data-aos-delay="400">
            I am Joy, and my music is more than just melodies and rhythms; it’s a heartfelt journey that blends the power of gospel with
            soul-stirring lyrics. With a mission to uplift and inspire, my music has touched hearts around the world, offering
            hope and positivity through every note.
          </p>

          <p className="about-description" data-aos="fade-up" data-aos-delay="600">
            But my work doesn’t stop with music. As a pastor, I am deeply committed to spreading the message of faith, love, and hope through
            ministry and evangelism. Over the years, I have dedicated myself to sharing the gospel, leading youth ministries, and serving communities
            with the message of God’s grace and redemption. 
          </p>

          <p className="about-description" data-aos="fade-up" data-aos-delay="800">
            Through both my music and ministry, I aim to reach those in need, offering a light in the darkest of times. Every song I create,
            every sermon I preach, and every outreach I lead is a reflection of my passion to see lives transformed by the love of Christ.
          </p>

          <p className="about-description" data-aos="fade-up" data-aos-delay="1000">
            Whether through my music or my ministry, my heart is set on spreading the message of God's love and bringing people closer to His truth.
          </p>

          <div className="social-icons" data-aos="fade-up" data-aos-delay="1200">
            <a href="https://www.facebook.com/" className="social-icon">
              <FaFacebook />
            </a>
            <a href="https://www.instagram.com/" className="social-icon">
              <FaInstagram />
            </a>
            <a href="https://x.com/?lang=en-in" className="social-icon">
              <FaTwitter />
            </a>
            <a href="https://www.youtube.com/@jammingroom" className="social-icon">
              <FaYoutube />
            </a>
            <a href="https://spotify.com/your-spotify-profile" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FaSpotify />
            </a>
          </div>
        </div>

        <div className="about-image" data-aos="fade-left">
          <img src={bwImage} alt="Joy" className="joy-image" />
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
