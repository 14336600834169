import React, { useEffect } from 'react';
import './HeroSection.css';
import AboutSection from './AboutSection'; // Importing AboutSection
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify, faApple, faAmazon, faSoundcloud, faYoutube } from '@fortawesome/free-brands-svg-icons';

import AOS from 'aos';
import 'aos/dist/aos.css';

const HeroSection = () => {
  // Initialize AOS for scroll animations
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration
      once: true, // Whether animation happens only once
    });
  }, []); // Empty dependency array ensures it runs once when the component mounts

  const handleListenNowClick = () => {
    const musicSection = document.getElementById('music-section');
    if (musicSection) {
      musicSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Generate a list of random musical notes
  const notes = ['&#9835;', '&#9833;', '&#9839;', '&#9834;'];

  const renderNotes = () => {
    const noteElements = [];
    for (let i = 0; i < 50; i++) {
      const randomNote = notes[Math.floor(Math.random() * notes.length)];
      const randomSize = Math.random() * 40 + 10; // Random size between 10px and 50px
      const randomLeft = Math.random() * 100; // Random left position (0% to 100%)
      const randomTop = Math.random() * 100; // Random top position (0% to 100%)
      const randomDelay = Math.random() * 2; // Random animation delay (0s to 2s)

      noteElements.push(
        <div
          key={i}
          className="note"
          style={{
            fontSize: `${randomSize}px`,
            left: `${randomLeft}%`,
            top: `${randomTop}%`,
            animationDelay: `${randomDelay}s`,
          }}
          dangerouslySetInnerHTML={{ __html: randomNote }}
        />
      );
    }
    return noteElements;
  };

  return (
    <div>
      <div className="hero">
        <div className="hero-content">
          <h1 className="hero-title" data-aos="fade-up"> {/* AOS fade-up animation */}
            Joy's Music: A Journey of Gospel and Inspiration
          </h1>
          <p className="hero-subtitle" data-aos="fade-up" data-aos-delay="200"> {/* AOS fade-up with delay */}
            "Dive into uplifting gospel tunes, heartfelt lyrics, and powerful performances."
          </p>
          <button
            className="cta-button"
            onClick={handleListenNowClick}
            data-aos="fade-up" // AOS fade-up animation
            data-aos-delay="400" // Add delay to animate after other elements
          >
            Listen Now
          </button>

          {/* Social Media Icons with AOS Animation */}
          <div className="music-icons" data-aos="fade-up" data-aos-delay="600"> {/* AOS fade-up animation with delay */}
            <a href="https://music.amazon.com/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faAmazon} size="2x" />
            </a>
            <a href="https://spotify.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faSpotify} size="2x" />
            </a>
            <a href="https://music.apple.com/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faApple} size="2x" />
            </a>
            <a href="https://soundcloud.com/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faSoundcloud} size="2x" />
            </a>
            <a href="https://www.youtube.com/@jammingroom" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} size="2x" />
            </a>
          </div>
        </div>

        <div className="musical-notes">{renderNotes()}</div>
      </div>

      {/* About Section */}
      <AboutSection />
    </div>
  );
};

export default HeroSection;
