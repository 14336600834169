import React, { useEffect } from 'react';
import './Contact.css'; // Ensure your styles are linked correctly

// You can add the Font Awesome library in your HTML head or import it in this file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify, faApple, faAmazon, faSoundcloud, faYoutube } from '@fortawesome/free-brands-svg-icons';
import AOS from 'aos'; // Import AOS for scroll animations
import 'aos/dist/aos.css'; // Import AOS styles

const Contact = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration
      once: false, // Trigger animations every time they come into view
      offset: 100, // Trigger animations before the element is fully in view
    });
  }, []);

  return (
    <section id="contact-section" className="contact-section">
      <div className="contact-container">
        {/* Contact Form */}
        <div className="form-container" data-aos="fade-up">
          <h2>Contact</h2>
          <p>I’d love to hear from you. Please fill out the form below to get in touch.</p>
          
          <form className="contact-form">
            <input type="text" name="name" placeholder="Your Name" required />
            <input type="email" name="email" placeholder="Your Email" required />
            <textarea name="message" placeholder="Your Message" required></textarea>
            <button type="submit">Send Message</button>
          </form>
        </div>

        {/* Branding Section */}
        <div className="branding-container" data-aos="fade-up" data-aos-delay="200">
          <h3>Joy's Music</h3>
          <p>Inspiring lives through faith, leadership, and music. Together, we can uplift and build a brighter future through music and faith.</p>
          
          {/* Social Media Icons */}
          <div className="music-icons" data-aos="zoom-in" data-aos-delay="400">
            <a href="https://music.amazon.com/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faAmazon} size="2x" />
            </a>
            <a href="https://spotify.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faSpotify} size="2x" />
            </a>
            <a href="https://music.apple.com/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faApple} size="2x" />
            </a>
            <a href="https://soundcloud.com/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faSoundcloud} size="2x" />
            </a>
            <a href="https://music.youtube.com/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} size="2x" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
