import React from 'react';
import HeroSection from './components/HeroSection.jsx';
import Navbar from './components/Navbar.jsx';
import MusicSection from './components/MusicSection.jsx';
import Gallery from './components/GallerySection.jsx';
import SkillsCertifications from './components/SkillsCertifications.jsx';
import Footer from './components/Footer.jsx';
import Contact from './components/Contact.jsx';

function App() {
  return (
    <div>
      <Navbar />
      <HeroSection />
      <MusicSection />
      <Gallery />
      <SkillsCertifications />
      <Contact/>
      <Footer />
    </div>
  );
}

export default App;
