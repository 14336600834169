import React, { useState, useEffect } from 'react';
import { FaPlay, FaPause } from 'react-icons/fa'; // Play and Pause icons
import './MusicSection.css';
import art3 from '../assets/3.JPG'; // Import album art
import art2 from '../assets/2.JPG'; // Import album art
import art1 from '../assets/1.jpg'; // Import album art
import mor_yeshu from '../assets/mor_yeshu.mp4'; // Import music file
import tor_nazar from '../assets/tor_nazar.mp4'; // Import music file
import tohe_prabhu from '../assets/tohe_prabhu.mp4'; // Import music file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify, faApple, faAmazon, faSoundcloud, faYoutube } from '@fortawesome/free-brands-svg-icons';
import AOS from 'aos'; // Import AOS
import 'aos/dist/aos.css'; // Import AOS styles

const MusicSection = () => {
  const [isPlaying, setIsPlaying] = useState(null); // Track which song is playing
  const [audio, setAudio] = useState(new Audio()); // Manage audio state

  const handlePlayPause = (index, musicFile) => {
    if (isPlaying === index) {
      audio.pause(); // Pause if the same song is clicked again
      setIsPlaying(null);
    } else {
      audio.src = musicFile;
      audio.play();
      setIsPlaying(index); // Play the clicked song
    }
  };

  // Initialize AOS when component mounts
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration
      once: false, // Trigger animations every time they are in view (not just once)
      offset: 100, // Offset to trigger animations a bit before the section is fully in view
    });
  }, []);

  return (
    <section id="music-section" className="music-section">
      <h2 className="music-title" data-aos="fade-up">My Music Portfolio</h2>
      <p className="music-subtitle" data-aos="fade-up" data-aos-delay="200">
        Explore my latest tracks, albums, and performances. Click on the play icons below to listen or watch.
      </p>
      
      <div className="music-cards">
        <div className="music-card" data-aos="fade-up" data-aos-delay="400">
          <div className="card-content">
            <img src={art3} alt="Album 1" className="album-art" />
            <div className="song-details">
              <h3 className="song-name">Mor Yeshu</h3>
              <p className="release-date">Releasing Soon</p>
              <button
                className="play-button"
                onClick={() => handlePlayPause(0, mor_yeshu)} // Play from assets
              >
                {isPlaying === 0 ? <FaPause /> : <FaPlay />}
              </button>
              <p className="song-description">
                It speaks about hope and inspiration.
              </p>
            </div>
          </div>
        </div>

        <div className="music-card" data-aos="fade-up" data-aos-delay="600">
          <div className="card-content">
            <img src={art2} alt="Album 2" className="album-art" />
            <div className="song-details">
              <h3 className="song-name">Tor Nazar</h3>
              <p className="release-date">Releasing Soon</p>
              <button
                className="play-button"
                onClick={() => handlePlayPause(1, tor_nazar)} // Play from assets
              >
                {isPlaying === 1 ? <FaPause /> : <FaPlay />}
              </button>
              <p className="song-description">
                This song speaks about faith and strength. A melody of empowerment.
              </p>
            </div>
          </div>
        </div>

        <div className="music-card" data-aos="fade-up" data-aos-delay="800">
          <div className="card-content">
            <img src={art1} alt="Album 3" className="album-art" />
            <div className="song-details">
              <h3 className="song-name">Tohe Prabhu (Live)</h3>
              <p className="release-date">Releasing Soon</p>
              <button
                className="play-button"
                onClick={() => handlePlayPause(2, tohe_prabhu)} // Play from assets
              >
                {isPlaying === 2 ? <FaPause /> : <FaPlay />}
              </button>
              <p className="song-description">
                A song of love and unity, bringing people together with harmonious tunes.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Social Media Icons */}
      <div className="music-icons" data-aos="fade-up" data-aos-delay="1000">
        <a href="https://music.amazon.com/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faAmazon} size="2x" />
        </a>
        <a href="https://spotify.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faSpotify} size="2x" />
        </a>
        <a href="https://music.apple.com/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faApple} size="2x" />
        </a>
        <a href="https://soundcloud.com/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faSoundcloud} size="2x" />
        </a>
        <a href="https://www.youtube.com/@jammingroom" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} size="2x" />
        </a>
      </div>
    </section>
  );
};

export default MusicSection;
