import React, { useState, useEffect } from 'react';
import './Gallery.css'; // Ensure to import the CSS file

// Import images directly from the src/assets folder
import colorImage from '../assets/color.jpg';
import bwImage from '../assets/bw.jpg';
import artImage from '../assets/art.jpg';
// import dogImage from '../assets/dog.jpg';
import guitarImage from '../assets/guitar.jpg';
import skyImage from '../assets/sky.jpg';
import roadImage from '../assets/road.jpg';

import AOS from 'aos'; // Import AOS
import 'aos/dist/aos.css'; // Import AOS styles

const Gallery = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [animationClass, setAnimationClass] = useState('');

  // Store the imported images in an array
  const images = [colorImage, bwImage, artImage, guitarImage, skyImage, roadImage];

  const handlePrev = () => {
    setAnimationClass('slide-prev'); // Apply slide-prev animation
    setTimeout(() => {
      setCurrentImage((prev) => (prev === 0 ? images.length - 1 : prev - 1));
      setAnimationClass(''); // Remove animation class after transition
    }, 500);
  };

  const handleNext = () => {
    setAnimationClass('slide-next'); // Apply slide-next animation
    setTimeout(() => {
      setCurrentImage((prev) => (prev === images.length - 1 ? 0 : prev + 1));
      setAnimationClass(''); // Remove animation class after transition
    }, 500);
  };

  // Initialize AOS when component mounts
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration
      once: false, // Trigger animations every time they are in view
      offset: 100, // Offset to trigger animations before fully in view
    });
  }, []);

  return (
    <section className="gallery-section">
      <div className="gallery-content">
        <div className="gallery-description" data-aos="fade-up">
          <h2 className="gallery-title">Gallery</h2>
          <p className="gallery-subtitle">Explore moments from my journey</p>
          <p className="gallery-story">
            Here are some of the best moments captured during my journey.
            Each image tells a unique story and memory.
          </p>
        </div>

        <div className="gallery-image-container" data-aos="fade-up" data-aos-delay="200">
          <div className={`image-slider ${animationClass}`}>
            <img
              src={images[currentImage]}
              alt={`Gallery Image ${currentImage + 1}`}
              className="gallery-image"
            />
          </div>
          <div className="navigation-controls" data-aos="fade-up" data-aos-delay="400">
            <button className="prev" onClick={handlePrev}>
              &#10094;
            </button>
            <button className="next" onClick={handleNext}>
              &#10095;
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
