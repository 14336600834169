import React from 'react';
import './Navbar.css';

const Navbar = () => {
  const handleConnectClick = () => {
    const connectSection = document.getElementById('contact-section');
    if (connectSection) {
      connectSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLogoClick = () => {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        Joy's Music
      </div>
      <button className="connect-btn" onClick={handleConnectClick}>
        Connect
      </button>
    </nav>
  );
};

export default Navbar;
