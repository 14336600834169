import React, { useEffect } from "react";
import "./SkillsCertifications.css";
import AOS from 'aos'; // Import AOS for scroll animations
import 'aos/dist/aos.css'; // Import AOS styles

const SkillsCertifications = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration
      once: false, // Trigger animations every time they come into view
      offset: 100, // Trigger animations a bit before the element is fully in view
    });
  }, []);

  return (
    <section className="skills-certifications-section">
      {/* Introductory Text */}
      <div className="intro-text" data-aos="fade-up">
        <h2>Skills & Certifications</h2>
        <p>
          Over the years, I have cultivated a diverse set of skills and earned
          certifications that reflect my journey and dedication. From leading
          God's ministry and guiding youth to excelling in music production and
          creative tools, I bring expertise, passion, and purpose to everything
          I do.
        </p>
      </div>

      {/* Skills and Expertise */}
      <div className="skills-container" data-aos="fade-up" data-aos-delay="200">
        <h3>My Skills</h3>
        <ul>
          <li>
            <strong>Ministry & Leadership:</strong> Work in God's ministry
            with over a decade of experience, leading youth initiatives, and
            mentoring young minds.
          </li>
          <li>
            <strong>Education:</strong> Graduated from Asia Evangelical College
            & Seminary, Bengaluru, Karnataka, India (2018).
          </li>
          <li>
            <strong>Music Production:</strong> Certified Music Producer by
            "producers.co" (2022). Proficient in Logic Pro, Ableton Live, Reaper.
          </li>
          <li>
            <strong>Creative Tools:</strong> Skilled in Premiere Pro, DaVinci Resolve, Final Cut
            Pro for video editing and storytelling.
          </li>
        </ul>
      </div>

      {/* Certifications */}
      <div className="certifications-container" data-aos="fade-up" data-aos-delay="400">
        <h3>Certifications</h3>
        <div className="certification-card glass-effect" data-aos="zoom-in" data-aos-delay="500">
          <h4>Music Production Certification</h4>
          <p>Certified by: Producers.co | Year: 2022</p>
        </div>
        <div className="certification-card glass-effect" data-aos="zoom-in" data-aos-delay="600">
          <h4>Master of Divinity</h4>
          <p>
            Institution: Asia Evangelical College & Seminary. Bengaluru, India
            | Year: 2018
          </p>
        </div>
      </div>
    </section>
  );
};

export default SkillsCertifications;
